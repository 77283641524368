import { FC } from 'react'
import gql from 'graphql-tag'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { HeaderDesktop_UserFragment } from 'generated/graphql'
import { TDesktopHeader } from 'app.layout/constant/pageLayout.type'
import ExternalNavBarDesktop from './component/ExternalNavBar/ExternalNavBar.desktop'
import HeaderLogo from './component/HeaderLogo/HeaderLogo'
import SearchBarDesktop from './component/SearchBar/SearchBar.desktop'
import TemporaryBanner from './component/TemporaryBanner'
import UserAction from './component/UserAction/UserAction'

gql`
  fragment HeaderDesktop_user on User {
    ...UserAction_user
  }
`

interface IFHeaderDesktopProps extends TDesktopHeader {
  currentUser?: HeaderDesktop_UserFragment | null
  loading?: boolean
  isMainLogoTitle?: boolean
}

const HeaderDesktop: FC<IFHeaderDesktopProps> = ({
  size,
  isSticky = false,
  searchBar = false,
  externalNavBar = false,
  currentUser,
  loading,
  isMainLogoTitle = false,
}) => {
  const { pathname } = useRouter()

  const isMainPage = pathname === '/'

  return (
    <StyledWrapper isSticky={isSticky} isSizeSmall={size === 'small'}>
      {externalNavBar && (
        <ExternalNavBarDesktop currentUser={currentUser} loading={loading} />
      )}
      <div className="header-container">
        <div className="lt">
          {isMainLogoTitle ? (
            <h1>
              <HeaderLogo className="logo" />
            </h1>
          ) : (
            <HeaderLogo className="logo" />
          )}
          {searchBar && <SearchBarDesktop isMainPage={isMainPage} />}
        </div>
        {isMainPage && <TemporaryBanner />}
        {!externalNavBar && (
          <UserAction currentUser={currentUser} loading={loading} />
        )}
      </div>
    </StyledWrapper>
  )
}

export default HeaderDesktop

type TStyleProps = {
  isSticky: boolean
  isSizeSmall: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1148px;
    height: ${({ isSizeSmall }) => (isSizeSmall ? '114px' : '135px')};
    margin: 0 auto;
    padding: 0 24px;

    .lt {
      display: flex;
      align-items: center;
      .logo {
        width: 145px;
        height: 34px;
        margin-right: 40px;
        cursor: 'pointer';
      }
    }
  }
`
