import { useDomain } from 'context'
import { useOpenCustomerMessageForm } from 'components/customer-message-form'
import {
  ACTIVITY_BOT,
  AD_CONTACT,
  CONTEST_BOT,
  COVERLETTER_BOT,
  FB_CONTESTWHAT,
  FB_UNIVWHAT,
  FINANCIAL_REPORTS,
  INTRODUCE_LINKAREER,
  LINKAREER_BLOG,
  LINKAREER_POST,
  RECRUIT_BOT,
  SERVICE_FEEDBACK_GOOGLE_FORM_URL,
  SPECUPAD_JOBPOSTS,
  SPECUP_CAFE,
} from 'constants/urls'

const useFooterItems = () => {
  const { xen, linkareer } = useDomain()
  const openCustomerMessageForm = useOpenCustomerMessageForm()

  const footerItems = [
    {
      title: '링커리어',
      items: [
        { label: '링커리어 소개', href: INTRODUCE_LINKAREER },
        { label: '공지사항', href: `//${xen}/notice` },
        { label: '채용', href: SPECUPAD_JOBPOSTS },
        { label: '회사 공지사항', href: FINANCIAL_REPORTS },
      ],
    },
    {
      title: '고객문의',
      items: [
        { label: '광고 및 제휴 문의', href: AD_CONTACT },
        { label: '고객문의', onClick: openCustomerMessageForm },
        { label: 'FAQ', href: `//${linkareer}/faq/qna` },
        {
          label: '서비스 피드백',
          href: SERVICE_FEEDBACK_GOOGLE_FORM_URL,
          target: '_blank',
        },
      ],
    },
    {
      title: '이용안내',
      items: [
        { label: '이용약관', href: '/terms' },
        { label: '개인정보처리방침', href: '/privacy' },
        {
          label: '이용약관, 개인정보처리방침 변경 고지',
          href: 'https://timespread.notion.site/d65e8ba1fc2c443d80c69745327488af',
          target: '_blank',
        },
      ],
    },
    {
      title: '연관 서비스',
      items: [
        { label: '대외활동봇', href: ACTIVITY_BOT },
        { label: '공모전봇', href: CONTEST_BOT },
        { label: '채용봇', href: RECRUIT_BOT },
        { label: '자소서봇', href: COVERLETTER_BOT },
      ],
    },
    {
      title: '',
      items: [
        { label: '맞춤공고', href: `//${linkareer}/accounts/profile` },
        { label: '공고달력', href: `/calendar` },
        { label: '블로그', href: LINKAREER_BLOG },
        { label: '포스트', href: LINKAREER_POST },
      ],
    },
    {
      title: '',
      items: [
        { label: '스펙업', href: SPECUP_CAFE },
        { label: '대외활동 뭐하지', href: FB_UNIVWHAT },
        { label: '공모전 뭐하지', href: FB_CONTESTWHAT },
      ],
    },
  ]
  return { footerItems }
}

export default useFooterItems
