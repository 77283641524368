import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  NotificationScope,
  useGqlPageLayoutDesktopQuery,
} from 'generated/graphql'
import NotSupportedBrowser from 'app.layout/component/NotSuppotedBrowser'
import { TDesktopRenderOptions } from 'app.layout/constant/pageLayout.type'
import FooterDesktop from './Footer/Footer.desktop'
import HeaderDesktop from './Hearder/Header.desktop'
import NavBarDesktop from './NavBar/NavBar.desktop'

gql`
  query gqlPageLayoutDesktop($notificationType: NotificationScope) {
    currentUser {
      ...HeaderDesktop_user
    }
  }
`

interface IFPageLayoutDesktopProps {
  className?: string
  renderOptions: TDesktopRenderOptions
  isMainLogoTitle?: boolean
}

const PageLayoutDesktop: FC<IFPageLayoutDesktopProps> = ({
  className,
  children,
  renderOptions,
  isMainLogoTitle,
}) => {
  const { Header, NavBar, Footer } = renderOptions

  const { data, loading } = useGqlPageLayoutDesktopQuery({
    variables: { notificationType: NotificationScope.NOTIFICATION },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  return (
    <StyledWrapper className={className}>
      <NotSupportedBrowser />
      {Header && (
        <HeaderDesktop
          isMainLogoTitle={isMainLogoTitle}
          size={Header.size}
          isSticky={Header.isSticky}
          searchBar={Header.searchBar}
          externalNavBar={Header.externalNavBar}
          currentUser={data?.currentUser}
          loading={loading}
        />
      )}
      {NavBar && (
        <NavBarDesktop
          activeKey={NavBar.activeKey}
          isSticky={NavBar.isSticky}
          isLogin={!!data?.currentUser}
          userType={data?.currentUser?.type}
        />
      )}
      <main className="main-container">{children}</main>
      {Footer && <FooterDesktop />}
    </StyledWrapper>
  )
}

export default PageLayoutDesktop

const StyledWrapper = styled.div`
  min-width: 1280px;
  background-color: #fff;
  .main-container {
    max-width: 1148px;
    margin: 0 auto;
    padding: 40px 24px 80px;
  }
`
