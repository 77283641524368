import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { LinkareerLogoWithName } from 'svgIcons'
import { APP_STORE_URL, PLAY_STORE_URL } from 'app.constants/appInstallUrls'

const FooterAppInstall: FC = () => {
  return (
    <StyledWrapper>
      <LinkareerLogoWithName noColor className="logo" />
      <div className="store-badge-list">
        <Link href={PLAY_STORE_URL}>
          <a target="_blank" rel="noopener noreferrer">
            <Image
              src="/static/images/app-banner/play-store-badge.png"
              alt="플레이스토어"
              width={185}
              height={75}
            />
          </a>
        </Link>
        <Link href={APP_STORE_URL}>
          <a target="_blank" rel="noopener noreferrer">
            <Image
              src="/static/images/app-banner/app-store-badge.png"
              alt="앱스토어"
              width={126}
              height={42}
            />
          </a>
        </Link>
      </div>
    </StyledWrapper>
  )
}

export default FooterAppInstall

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .logo {
    width: 128px;
    color: #ccc;
  }
  .store-badge-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
