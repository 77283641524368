import gql from 'graphql-tag'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { useGqlAdPlacementQuery } from 'generated/graphql'

gql`
  query gqlAdPlacement($adPlacementId: ID!) {
    adPlacement(id: $adPlacementId) {
      defaultImage {
        url
      }
      defaultLandingURL
      name
    }
  }
`

const TEMPORARY_BANNER_ID = '7'

const TemporaryBanner = () => {
  const { data, loading } = useGqlAdPlacementQuery({
    variables: {
      adPlacementId: TEMPORARY_BANNER_ID,
    },
  })

  const landingUrl = data?.adPlacement?.defaultLandingURL ?? ''
  const bannerName = data?.adPlacement?.name ?? ''
  const imageUrl = data?.adPlacement?.defaultImage?.url ?? ''

  if (loading) {
    return null
  }

  return (
    <StyledWrapper>
      <Link href={landingUrl}>
        <a aria-describedby={bannerName}>
          <Image
            src={imageUrl}
            alt={bannerName}
            width={308}
            height={76}
            quality={100}
            unoptimized
          />
          {bannerName}
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default TemporaryBanner

const StyledWrapper = styled.div`
  width: 305px;
  height: 76px;
  margin-left: 69px;

  & > a {
    width: 100%;
    height: 100%;
    font-size: 0;
    display: block;
  }
`
